<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="保存"
      back-button-text="上一步"
      next-button-text="下一步"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <tab-content title="PIA基本信息" :before-change="validationForm">
        <validation-observer ref="piaBase" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">PIA基本信息</h5>
              <small class="text-muted"></small>
            </b-col>

            <b-col md="6">
              <b-form-group label="发起人" label-for="name">
                <validation-provider #default="{ errors }" name="username">
                  <b-form-input
                    id="name"
                    v-model="item.ownerName"
                    :state="errors.length > 0 ? false : null"
                    placeholder="发起人"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="起止时间" label-for="range">
                <validation-provider #default="{ errors }" name="range">
                  <flat-pickr
                    v-model="rangeDate"
                    class="form-control"
                    :config="{ mode: 'range' }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="标题" label-for="title">
                <validation-provider #default="{ errors }" name="title">
                  <b-form-input
                    id="title"
                    v-model="item.title"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="简介" label-for="overview">
                <validation-provider #default="{ errors }" name="overview">
                  <b-form-input
                    id="overview"
                    v-model="item.overview"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <tab-content title="选择参与部门" :before-change="validationForm">
        My first tab content
      </tab-content>
      <tab-content title="选择PIA模版" :before-change="validationForm">
        My first tab content
      </tab-content>
      <tab-content title="启动PIA流程" :before-change="validationForm">
        My first tab content
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { required, email } from "@validations";

import flatPickr from "vue-flatpickr-component";

export default {
  name: "PiaProcessEdit",

  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    flatPickr,
  },

  data() {
    return {
      item: null,

      rangeDate: [],
    };
  },

  created() {
    let id = this.$route.params.id;
    if (id !== "new") {
      this.$http.get("/pias/" + id).then((res) => {
        this.item = res.data.data;
      });
    } else {
      this.item = {
        id: 0,
        eid: 0,
        templateId: 0,
        ownerName: "DPO",
        ownerId: 107,
        title: null,
        summary: null,
        start: null,
        end: null,
        progress: 0,
        status: 1,
      };
    }
  },

  methods: {
    formSubmitted() {
      //
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
