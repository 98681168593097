var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{staticClass:"mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"square","finish-button-text":"保存","back-button-text":"上一步","next-button-text":"下一步"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":"PIA基本信息","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"piaBase",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("PIA基本信息")]),_c('small',{staticClass:"text-muted"})]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"发起人","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":"发起人","readonly":""},model:{value:(_vm.item.ownerName),callback:function ($$v) {_vm.$set(_vm.item, "ownerName", $$v)},expression:"item.ownerName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"起止时间","label-for":"range"}},[_c('validation-provider',{attrs:{"name":"range"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ mode: 'range' }},model:{value:(_vm.rangeDate),callback:function ($$v) {_vm.rangeDate=$$v},expression:"rangeDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"标题","label-for":"title"}},[_c('validation-provider',{attrs:{"name":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null},model:{value:(_vm.item.title),callback:function ($$v) {_vm.$set(_vm.item, "title", $$v)},expression:"item.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"简介","label-for":"overview"}},[_c('validation-provider',{attrs:{"name":"overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"overview","state":errors.length > 0 ? false : null},model:{value:(_vm.item.overview),callback:function ($$v) {_vm.$set(_vm.item, "overview", $$v)},expression:"item.overview"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"选择参与部门","before-change":_vm.validationForm}},[_vm._v(" My first tab content ")]),_c('tab-content',{attrs:{"title":"选择PIA模版","before-change":_vm.validationForm}},[_vm._v(" My first tab content ")]),_c('tab-content',{attrs:{"title":"启动PIA流程","before-change":_vm.validationForm}},[_vm._v(" My first tab content ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }